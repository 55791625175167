@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
 
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  /* font-size: 17px; */
  margin: 0;
  padding: 0;
  
}


.css-1t29gy6-MuiToolbar-root {
  
    padding-left: 0px !important;
    padding-right: 0px !important;
  
}


.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  vertical-align: baseline !important;
}

.css-hjgewa-MuiButtonBase-root-MuiButton-root {
  vertical-align: top !important;
}

.btn {
  width: 100%;
  padding: 17px 0;
  display: block;
  font-weight: bold;
  font-size: 15px;
  border-radius: 4px;
}

.btn.btn-outline {
  background: 0 0
}

.btn-outline.btn-primary {
  border-color: #FF00DF;
  color: #FF00DF
}

.btn-outline.btn-primary:focus,
.btn-outline.btn-primary:hover {
  color: #FFFFFF;
  border-color:#FF00DF;
  background-color: #FF00DF;
}

.css-191lty2, .css-191lty2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-191lty2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tableHead {
  display: none !important;
}

.css-ehg4e8 {
  flex-direction: row-reverse !important;
}

@media screen and (max-width: 580px) {
  .video-responsive iframe {
    height: 320px;
    width: 100%;
  }
  .left {
    margin-right: 5px !important;
  }

  .pricingMainHeading {
    font-size: 40px !important;
}
}

@media screen and (max-width: 800px) {
  

  .pricingMainHeading {
    font-size: 45px !important;
}
}

/* .video-responsive iframe {
  height: 500px;
  width: 500px;
} */

.buyLinks {
  text-decoration: none;
}

.left {
  margin-right: 250px;
}

a {
  text-decoration: none;
}

#menu-appbar a {
  color: black;
}

.messageText {
  color:#572523;
}

.messsageInput {
  width: 444px
}

.errorHeading {
  color: #AF0606
}

.errorMessage {
  color: #cc0000
}

.erroranchor {
  text-decoration: underline
}

@media screen and (max-width: 460px) {

  .searchbar {
    width: 330px !important;
  }

  #autocompleteData {
    width: 330px !important;
  }
  #copytoClipButton {
    margin-left: 130px;
  }

  .messsageInput {
    width: 334px;
  }

  .googleGuggestSearchBox {
    width: 330px !important;
  }

  .pricingMainHeading {
     font-size: 30px !important;
}

}

.message {
  padding-top: 7px;
  color: #AF0606;
}



.indexchecker {
  max-width: 900px;
}
.bulkindexchecker {
  max-width: 1000px;
}

.footer {
  /* position: absolute; 
  bottom: 0; 
  width: 100%; */
  /* position: "fixed"; */
  /* left: 0; */
  /* bottom: 0; */
  /* right: 0; */
  /* height: 22.5rem; */
  background-color: black;
  color: white;
}

#mozdiv1 {
  /* font-size: 20px; */
  letter-spacing: .2rem;
}

.footerList {
  padding-left: 0 !important;
  padding-right: 0 !important;
}


.footerList > a, li {
  color: #FF00DF !important;
}

.bigContainer {
  height: 100vh !important;
}

.textareaColor:hover {
  border-color: #FF00DF !important;
}

 textarea.textareaColor:focus {
  border-color: #FF00DF !important;
}

.fear {
  display: block !important;
}

.buttonSubmit {
  margin-left: 10px !important;
}

.errorHeading56 {
  color: #008000;
  margin-top: 50px;
  margin-bottom: 50px;
}

.errorHeadingvalidate {
  color:#008000;
}

.hero {
  /* z-index: 999; */
  /* margin-top: -79px; */
	background-color: #000000;
	background-image: linear-gradient(rgba(0,19,123,0.84),rgba(0,19,123,0.84)),url("https://anthonyhayes.me/wp-content/uploads/31659998_woman-working-at-home-office-hand-on-keyboard-close-up-scaled-1.webp");
  background-position: center;
	background-size: cover;
  background-attachment: fixed;
	color: #ffffff;
	padding: 1em;
	text-align: center;
  /* height: 100vh;
  weight: 100vw; */
}

.pricingMainParagraph {
  font-size: 20px;
  font-family: 'Lato', sans-serif;

}

.pricingMainHeading {
  font-size: 68px;
  font-family: 'Lato', sans-serif;

}

.buyNow {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}

.borderBottom {
  border-bottom: 1px solid rgba(0,0,0,0.14);
}

.borderRight {
  border-right: 1px solid rgba(0,0,0,0.14);
}

.buyButton {
  background-color: rgb(19,114,211) !important;
}
.buyButtona {
  color: rgb(19,114,211) !important;
}
.buyButton:hover {
  background-color: rgb(215,84,35) !important;;
}

.sizeBigger {
  font-family: 'Lato', sans-serif;
  font-size: 17px !important;
  padding-right: 23px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 23px !important;
  border-radius: 50px !important;
}

.headingMainWhat {
font-family: 'Lato', sans-serif;
line-height: 1.4em;
}
.headingMainWhatA {
color: rgb(106, 107, 108);
font-weight: 300;
}

#testimonial {
  background-image: linear-gradient(rgb(245,245,245),rgb(245,245,245)) !important;
    background-size: auto !important;
    background-position: 50% 50% !important;
    background-attachment: scroll !important;
    background-repeat: no-repeat !important;
    padding-bottom: 30px !important;
}

.starsParagraph {
  font-weight: bold;
}

.feedbackShadow {
  box-shadow: rgb(0 0 0 / 8%) 0px 3px 10px 0px;
}

.factFile {
  font-size: 21px !important;
  font-style: italic;
}

.headingMainWhatb {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.backGroundBlack {
  /* background-color: #000000;
	background-image: linear-gradient(rgba(0,19,123,0.84),rgba(0,19,123,0.84)),url("https://anthonyhayes.me/wp-content/uploads/copy2-pattern-4.png");
  background-position: center;
	background-size: cover;
  background-attachment: fixed;
	color: #ffffff;
	padding: 1em;
	text-align: center;
  width: 1000px;
  height: 500px; */
  background-color: rgb(7,35,58) !important;
  background-image: linear-gradient(rgba(7,58,55,0.18),rgba(7,58,55,0.18)),url("https://anthonyhayes.me/wp-content/uploads/copy2-pattern-4.png") !important;
  background-size: auto,auto !important;
  background-position: 50% 50%,50% 50% !important;
  background-attachment: scroll,scroll !important;
  background-repeat: no-repeat,repeat !important;
  color: #ffffff !important;
}

.orangeColor {
  color: rgb(255, 166, 45);
}


/* Question content */
.faq__content {
  border-bottom: 1px solid var(--light-grayish-blue);
  position: relative;
}

.faq__content:last-child {
  margin-bottom: 1.5rem;
}

/* Question styles*/
.faq__content-question {
  color: var(--very-dark-grayish-blue);
  font-size: 18px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: padding-bottom .4s;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq__content-question:hover {
  color: var(--soft-red);
  transition: color .2s;
}

@media (min-width: 1100px) {
  .faq__content-question {
      padding-right: 1rem;
  }
}

.arrow {
  cursor: pointer;
}

/* Magic starts here */
.itemCheckbox {
  display: none;
}
/* input[type="checkbox"] {
  display: none;
} */

input:checked~label .arrow {
  transform: rotate(180deg);
}

input:checked~.faq__content-question {
  font-weight: 500;
  padding-bottom: 0;
  transition: padding-bottom .4s;
}

input:checked~.faq__content-answer {
  max-height: 694px;
  margin-top: 12px;
  margin-bottom: 12px;
  transition: max-height .3s;
}
/* End magic */

/* Answer styles */
.faq__content-answer {
  /* color: var(--dark-grayish-blue); */
  margin: 0;
  width: 90%;
  max-height: 0;
  line-height: 2.0;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  transition: max-height .3s;
  border-bottom: 1px solid rgba(0,0,0,0.14);
}

.faq__content-answer-advance {
  background-color: rgb(245,245,245) !important;
  padding-left: 8px;
  padding-right: 8px;
}


.faq__content-question:hover {
  color: hsl(210deg 77% 54%);

}

.grayBackgroundColor {
  background-color: rgb(230, 230, 230);
  color: rgb(106, 107, 108) !important;
}

.grayBackgroundColorPadding {
  padding-left: 25px;
  padding-top: 25px;
}

.grayBackgroundColora {
  padding-bottom: 15px;
  font-weight: 300;
  font-size: 17px;
}

.boldest {
  font-weight: 500 !important;
}

.emptyBorder {
border-bottom:   4px solid rgb(106, 107, 108);
}

.fontest {
  font-size: 19px !important;
  color: rgb(106, 107, 108) !important;
}

.fontestq {
  font-weight: 500 !important;
}

.borderast {
  border: 1px solid rgb(106, 107, 108) !important;
}

.lineest {
  font-size: 17px !important;
  line-height: 1.8em !important;
}

.fogest {
  font-size: 20px;
  font-weight: 500 !important;
}

.bluest {
  background-color: rgb(12,129,187) !important;
  color: #fff;
}

.weighest {
  font-weight: 500 !important;
}

.gulliest {
  line-height: 1.8em !important;
  font-size: 19px !important;
  font-weight: 300 !important;
}

.parentBox {
  border: 1px solid rgba(255,255,255,0.22) !important;
  position: relative;
}

.css-i9gxme {
  margin-top: -10px !important;
}

.buyItNow {
  border-radius: 100px !important;
    overflow: hidden !important;
    letter-spacing: 2px !important;
    border-bottom: 3px solid rgba(0,0,0,0.12) !important;
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    padding-left: 38px !important;
    padding-right: 38px !important;
    background-color: rgb(255,166,45) !important;
    font-size: 20px !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -170%) !important;
}

.prices {
  color: #008fff !important;
}

@media only screen and (max-width: 480px) {
  .headingMainWhatMobile {
      font-size: 22px !important;
  }

  .stressImage {
    margin-top: 10px !important;
    width: 330px !important;
    height: 330px !important;
  }

  .mainBannerMobile {
    display: none;
  }

  #mainTestimonial {
    margin-top: 12px;
  }

  .headingMainWhatAMobile {
    font-size: 25px;
  }

  .stressImageAlways {
    /* margin-top: 10px !important; */
    width: 230px !important;
    height: 240px !important;
  }

  .stressImageAlwaysPadding {
    /* margin-top: 10px !important; */
    padding-left: 10px !important;
    /* padding-top: 5px !important; */
    padding-bottom: 12px !important;
  }

  .paddingTop {
    padding-top: 10px !important;
  }

  .headingMainWhatAMobile {
    padding-top: 1px !important;
    padding-left: 10px !important;
  }

  .funFactImage {
    /* margin-top: 10px !important; */
    width: 200px !important;
    height: 200px !important;
  }

  .funFactImageFlex {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .headingMainWhatAMobileb {
    font-size: 23px !important;
    padding-left: 5px;
  }

  .headingMainWhatp {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .mainHeroSectionMargin {
    margin-top: 2px;
  }

  .headingMainWhatc {
    padding-top: 1px !important;
  }

  .mainHeadingGet {
    font-size: 24px !important;
    padding-bottom: 10px !important;
  }

  .fontMainHeading {
    font-size: 25px !important;
    padding-top: 1px !important;
    margin-bottom: 12px !important;
  }

  .marginNeg {
    margin-top: -60px !important;
  }

  .marginFixed {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .backGroundBlackMargin {
    margin-top: 7px !important;
    padding-top: 19px !important;
    padding-bottom: 19px !important;
  }

  .headingFoos {
    font-size: 22px !important;
    line-height: 32px !important;
  }
  .headingFoo {
    font-size: 27px !important;
    /* line-height: 32px !important; */
  }

  .youtubeVideo {
    height: 250px !important;
  }

  .pricesPaddingFixed {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .pricesPaddingFixeda {
    margin-top: 1px !important;
    margin-bottom: 35px !important;
    /* margin-bottom: 1px !important; */
  }

  .imageFixed {
    width: 100% !important;
  }

  .buyItNow {
    border-radius: 100px !important;
      overflow: hidden !important;
      letter-spacing: 2px !important;
      border-bottom: 3px solid rgba(0,0,0,0.12) !important;
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      padding-left: 18px !important;
      padding-right: 18px !important;
      background-color: rgb(255,166,45) !important;
      font-size: 20px !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -340%) !important;
  }

  .paddingest {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  /* img {
    width: 100% !important;
  } */
}


@media only screen and (max-width: 789px) {
  
 .stressImageAlways {
    width: 200px !important;
  }
  .kaushalFlex {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .pFive {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .dDisplay {
    display: none;
  }

  .youtubeVideo {
    height: 300px !important;
  }

  

}


@media only screen and (max-width: 374px) {
  .buyItNow {
    border-radius: 99px !important;
    overflow: hidden !important;
    letter-spacing: 2px !important;
    border-bottom: 3px solid rgba(0,0,0,0.12) !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
    background-color: rgb(255,166,45) !important;
    font-size: 20px !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -290%) !important;

  }
}


.messagebox {
  /* color: rebeccapurple; */
  color: #FF00DF;
}


.spanSetter {
 color: 'red' !important;
 font-size: '20px' !important; 
}